import * as React from 'react'
const SvgSettingsBig = ({ title, titleId, ...props }) => (
  <svg
    viewBox="0 0 28 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.739.09c2.36 0 4.348 1.636 4.806 3.891.481.082.857.484.857 1.025 0 .546-.424.943-.904 1.024a4.919 4.919 0 0 1-4.806 3.89 4.92 4.92 0 0 1-4.804-3.88H1.45C.88 6.04.415 5.576.415 5.007c0-.57.465-1.035 1.035-1.035h15.485A4.92 4.92 0 0 1 21.74.09Zm0 7.76a2.833 2.833 0 0 1-2.845-2.844 2.833 2.833 0 0 1 2.845-2.846 2.833 2.833 0 0 1 2.845 2.846 2.833 2.833 0 0 1-2.845 2.845ZM26.414 25.756h-.059a4.92 4.92 0 0 0-4.803-3.88 4.92 4.92 0 0 0-4.804 3.88H1.45c-.57 0-1.035.466-1.035 1.035 0 .57.465 1.035 1.035 1.035H16.7a4.92 4.92 0 0 0 4.804 3.88 4.92 4.92 0 0 0 4.804-3.88h.058c.57 0 1.035-.465 1.035-1.035 0-.563-.412-1.035-.988-1.035Zm-4.909 3.927c-1.582 0-2.845-1.265-2.845-2.798v-.094c0-1.58 1.31-2.845 2.845-2.845a2.833 2.833 0 0 1 2.845 2.845c0 1.583-1.266 2.892-2.845 2.892ZM26.414 14.864H10.975a4.92 4.92 0 0 0-4.804-3.88 4.919 4.919 0 0 0-4.805 3.89c-.48.081-.905.478-.905 1.025 0 .545.382.946.905 1.025a4.919 4.919 0 0 0 4.805 3.89 4.92 4.92 0 0 0 4.804-3.88h15.439c.57 0 1.035-.466 1.035-1.035 0-.57-.466-1.035-1.035-1.035Zm-20.29 3.88a2.833 2.833 0 0 1-2.845-2.845 2.833 2.833 0 0 1 2.846-2.846A2.833 2.833 0 0 1 8.97 15.9a2.833 2.833 0 0 1-2.845 2.845Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgSettingsBig
